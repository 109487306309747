import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

import Header from '../Layout/Header';
import ContentArea from '../ContentArea';

import { CdsInlineButton } from '@cds/react/button';
import { CdsIcon } from '@cds/react/icon';
import { ClarityIcons, angleIcon } from '@cds/core/icon';

function NotFound(props) {

  ClarityIcons.addIcons(angleIcon);

  return(
    <>
      <Helmet>
        <title>Tanzu Design | Page not found</title>
      </Helmet>
      <Header title="Tanzu Design" />
      <div className="top-rail" alt="An illustration of clouds with a blue stripe at a 45 degree angle" style={{ background : "#ffffff url(/img/clouds.png) center no-repeat" }}></div>
      <div className="content-container">
        <ContentArea>
          <div className="clr-row">
            <div className="clr-col-6 clr-offset-sm-3">
              <p cds-text="h1" cds-layout="m-b:lg">Well, this is awkward</p>
              <p cds-text="message" cds-layout="m-b:lg">We can&rsquo;t seem to find the page you&rsquo;re looking for.</p>
              <p cds-text="body"><Link to="/" cds-text="link" alt="A link to go to the Tanzu Design home page"><CdsInlineButton>Go to home page<CdsIcon shape="angle" direction="right" /></CdsInlineButton></Link></p>
            </div>
          </div>
        </ContentArea>
      </div>
    </>
  );
}

export default NotFound;