import Header from './Header';
import Footer from './Footer';

function Layout({ children }) {
  return(
    <>
      <Header title="Tanzu Design" />      
      <main className="content-container">
        {children}
      </main>
      <Footer />
    </>
  );
}

export default Layout;