import { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams
} from 'react-router-dom';

import ga from './lib/ga';

import './App.css';

import NotFound from './components/NotFound';

import Home from './app/Home';
import { ConfirmOffboard, Offboard } from './app/Offboard';
import { ConfirmOnboard, Onboard } from './app/Onboard';
import { OpportunitiesList } from './app/Research';
import { Consent } from './app/Consent';
import VmugVoice from './app/Content/VmugVoice';

function App() {

  let location = useLocation(),
    [ searchParams, setSearchParams ] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('utm_source')) {
      ga.set({ campaignSource : searchParams.get('utm_source') });
    }
    if (searchParams.has('utm_medium')) {
      ga.set({ campaignMedium : searchParams.get('utm_medium') });
    }
    if (searchParams.has('utm_campaign')) {
      ga.set({ campaignName : searchParams.get('utm_campaign') });
    }

    ga.pageview(location.pathname, document.title);
  }, [location]);

  useEffect(() => {
    const hash = location.hash.slice(1); // Remove the '#' character from the hash
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), 5);
      }
    }
  }, [location]);

  return (
    <>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/research">
          <Route index element={<OpportunitiesList />} />
          <Route path="opportunities/:id" element={<Consent />} />
        </Route>
        <Route path="/newsletter/withdraw" element={<Navigate to="/withdraw" />} />
        <Route path="/join-us">
          <Route index element={<Onboard />} />
          <Route path="confirm" element={<ConfirmOnboard />} />
        </Route>
        <Route path="/withdraw">
          <Route index element={<Offboard />} />
          <Route path="confirm" element={<ConfirmOffboard />} />
        </Route>
        <Route path="/vmug-voice" element={<VmugVoice />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
