import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';
import { CdsButton } from '@cds/react/button';
import { CdsIcon } from '@cds/react/icon';
import { ClarityIcons, angleIcon } from '@cds/core/icon';

import Header from '../../components/Layout/Header';
import ContentArea from '../../components/ContentArea';
import Footer from '../../components/Layout/Footer';

import './VmugVoice.css';

ClarityIcons.addIcons(angleIcon);

function VmugVoice(props) {
  return(
    <>
      <Helmet>
        <title>Tanzu Design | VMUG Voice</title>
      </Helmet>
      <Header title="Tanzu Design" />
        <div className="top-rail" alt="A photograph of hands hold markers and sticky notes while looking at wireframes and illustrations"></div>
        <div className="content-container">
          <ContentArea>
            <div className="clr-row">
              <div className="clr-col-8 clr-offset-sm-2">
                <p cds-text="display semibold" cds-layout="m-b:lg">Help shape the future of VMware Tanzu</p>
                <p cds-text="section" cds-layout="m-b:xl">Are you an Application Developer, Kubernetes Platform Engineer, Platform Owner, or Application Owner? If so, we want your input to shape and enhance the user experience of <a href="https://tanzu.vmware.com" alt="Link to the VMware Tanzu web site">VMware Tanzu</a>, the leading platform that empowers developers to create exceptional modern applications. Join the Tanzu Design team and participate directly in our user research by <Link to="/research/signup" alt="Link to sign up for the monthly Tanzu Design Research newsletter">signing up for our informative newsletter</Link>.</p>
                <p cds-text="body" cds-layout="m-b:lg">As the Tanzu Design team, our mission is to understand the needs and pain points of Tanzu users and design solutions that truly enhance their experience. We employ human-centered design methods and principles to create meaningful improvements in the Tanzu product. Now, we need your expertise and unique perspective to make our efforts even more impactful.</p>
                <p cds-text="body" cds-layout="m-b:lg">By signing up for our monthly newsletter, you'll gain exclusive access to 5 to 7 research topics where <b>we need your hands-on input</b>. These topics range from usability testing to concept validation and ideation. We value your direct input and have designed each session to cater to individual participants, ensuring that we capture your unique needs and insights. As a thank you, we are pleased to offer <strong>200 <a href="https://www.vmware.com/company/rewards.html" alt="Link to VMware Rewards program" target="_blank" rel="noreferrer">VMware Rewards</a> points for each research study you participate in</strong>. If you haven't already joined VMware Rewards, <a href="https://www.vmware.com/company/rewards.html" alt="Link to VMware Rewards program" target="_blank" rel="noreferrer">sign up today to start earning rewards!</a></p>
                <p cds-text="body" cds-layout="m-b:lg">Your feedback is crucial to our process. Every piece of input we receive will be carefully consolidated, evaluated, and considered as we strive to improve all aspects of the VMware Tanzu experience. By participating, you'll have a direct impact on the product's development and be at the forefront of shaping its future.</p>
                <p cds-text="body" cds-layout="m-b:lg">Join us on this exciting journey of user research and improvement. Together, we can revolutionize the VMware Tanzu experience and empower developers to build outstanding applications. Don't miss this opportunity to be a part of something groundbreaking.</p>
                <p cds-text="body" cds-layout="m-b:lg">Sign up for our newsletter today and let your voice be heard in the design process. Together, we'll create a better future for VMware Tanzu and its users.</p>
                <p>
                  <Link to="/newsletter/signup" alt="A link to sign up for the monthly Tanzu Design research newsletter">
                    <CdsButton>Sign up for our monthly newsletter<CdsIcon shape="angle" direction="right" /></CdsButton>
                  </Link>
                </p>
              </div>
            </div>
          </ContentArea>
        </div>
        <Footer />
    </>
  );
}

export default VmugVoice;