import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { analytics } from '../../lib/firebase';
import { logEvent } from 'firebase/analytics';

import Layout from "../../components/Layout";
import ContentArea from "../../components/ContentArea";

function ConfirmOffboard(props) {

  useEffect(() => {
    logEvent(analytics, 'withdraw');
  });

  return(
    <Layout>
      <Helmet>
        <title>Tanzu Design | Withdraw confirmed</title>
      </Helmet>
      <ContentArea>
        <div cds-layout="grid cols@sm:12 cols@md:8">
          <div cds-layout="col@md:start-3">
            <p cds-text="display" cds-layout="p-b:xl">Withdraw confirmed</p>
            <p cds-text="body" cds-layout="p-b:lg">Thank you for participating in the Tanzu Design user research program and we greatly appreciate your feedback.</p>
            <p cds-text="body">This confirms your withdraw from the Tanzu Design user research program. If you would like to resume participation, you may <Link to="/sign-up" alt="A link to sign up again for the Tanzu Design research program">sign up</Link> again. If you have any questions, please reach out to us at <a href="mailto:research@tanzudesign.io" cds-text="link">research@tanzudesign.io</a>.</p>
          </div>
        </div>
      </ContentArea>
    </Layout>
  );
}

export { ConfirmOffboard }