import { products, personas } from './constants';

const opportunities = [{
  id : 'ed3VT',
  title : 'Tanzu UX Suggestions Meeting',
  description : 'Have you noticed something about Tanzu products you want to discuss with the team responsible for designing the user experiences?  Do you have ideas for making Tanzu products even better? You can schedule a 30-minute meeting to share your thoughts and suggestions with the Tanzu UX team.',
  length : 30,
  personas : [
    personas.APP_DEVELOPER,
    personas.APP_OWNER,
    personas.DEV_OPS,
    personas.FINANCE,
    personas.LOB_EXECUTIVE,
    personas.PLATFORM_ENGINEER,
    personas.PLATFORM_OWNER,
    personas.SECURITY_TEAM
  ],
  products : ['Any Tanzu products'],
  points : 200,
  researcher : 'Mary Lee',
  posted : '3/15/2024',
  signupUrl : 'https://scheduler.zoom.us/mary-lee-vd4u3z/tanzu-ux-suggestions'
}]

export default opportunities;