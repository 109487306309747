import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

import { CdsButton } from '@cds/react/button';

import Layout from '../../components/Layout';
import ContentArea from '../../components/ContentArea';

import opportunities from '../../data/opportunities';

const OpportunitiesCards = (props) => {
  console.log(opportunities);
  return opportunities.map((o, i) => (
    <div className="clr-col-lg-6 clr-col-12" key={i} id={`Study_${o.id}`}>
      <div className="card">
        <h3 className="card-header">{o.title}</h3>
        <div className="card-block">
          <p className="card-text">{o.description}</p>
          <dl>
            <dt>Anticipated length</dt>
            <dd>{o.length} minutes</dd>
            <dt>Personas</dt>
            <dd>{o.personas.join(', ')}</dd>
            <dt>Products</dt>
            <dd>{o.products.join(', ')}</dd>
            <dt>VMware Rewards points</dt>
            <dd>
              Earn {o.points} <a href="https://www.vmware.com/company/rewards.html" alt="Link to VMware Rewards program" target="_blank" rel="noreferrer">VMware Rewards points</a> for participating in this study
            </dd>
          </dl>
        </div>
        <div className="card-footer">
          <Link to={`opportunities/${o.id}`} alt="Sign up for research study"><CdsButton action="flat-inline">Sign up</CdsButton></Link>
        </div>
      </div>
    </div>
  ))
}

const EmptyOpportunities = (props) => {
  return(<div className="clr-col-12" cds-text="body">Sorry, there are no available opportunities at this time. Please <Link to="/join-us">join the research program</Link> to be notified of new research opportunities.</div>);
}


function OpportunitiesList(props) {

  return(
    <>
      <Helmet>
        <title>Tanzu Design | Research Opportunities</title>
      </Helmet>
      <Layout>
        <ContentArea>
          <div cds-layout="grid cols@sm:12 cols@md:8">
            <div cds-layout="col@md:start-3">
              <p cds-text="display" cds-layout="p-b:xl">Research Opportunities</p>
              <div className="clr-row">
              {(opportunities.length > 0) ? <OpportunitiesCards /> : <EmptyOpportunities />}
              </div>
            </div>
          </div>
        </ContentArea>
      </Layout>
    </>
  );
}

export { OpportunitiesList }
