import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import Layout from '../../components/Layout';
import ContentArea from '../../components/ContentArea';

import { CdsCheckbox } from '@cds/react/checkbox';
import { CdsButton } from '@cds/react/button';

import opportunities from '../../data/opportunities';

function Consent(props) {
  
  const { id } = useParams();
  const o = opportunities[_.findIndex(opportunities, ['id', id])];

  const [ dataCollectionConsent, setDataCollectionConsent ] = useState(false);
  const [ ndaConsent, setNdaConsent ] = useState(false);
  
  const handleDataCollectionConsentChanged = e => { setDataCollectionConsent(e.target.checked); }
  const handleNdaConsentChanged = e => { setNdaConsent(e.target.checked); }
  const handleNextActionClick = e => { window.location.href = o.signupUrl; }

  const canBeSubmitted = () => {
    if (dataCollectionConsent && ndaConsent) {
      document.getElementById('NextAction').removeAttribute('disabled');
    } else {
      document.getElementById('NextAction').setAttribute('disabled', '');
    }
  }

  useEffect(() => canBeSubmitted());

  return(
    <>
      <Helmet>
        <title>Tanzu Design | Research consent</title>
      </Helmet>
      <Layout>
        <ContentArea>
          <div cds-layout="grid cols@sm:12 cols@md:8">
            <div cds-layout="col@md:start-3">
              <section cds-layout="m-b:xl">
                <p cds-text="display" cds-layout="p-b:xl">Research consent</p>
                <p cds-text="body" cds-layout="p-b:xl">Thank you for your willingness to help us improve VMware Tanzu by participating in the research study <em>{o.title}</em>. After consenting to data collection and non-disclosure agreement, you will be able to select a time slot that is convenient for you.</p>
                <p cds-text="section" cds-layout="p-b:lg">Data collection consent</p>
                <p cds-text="body" cds-layout="p-b:lg">By participating in the Tanzu Design user research program, you consent to the Tanzu Design team collecting and retaining your personally identifiable information (PII) and audio and/or video recordings to facilitate user research for product improvement. All personally identifiable information (PII) is managed in accordance with <a href="https://www.vmware.com/help/privacy.html" target="_blank" cds-text="link" rel="noreferrer" alt="VMware's Global Privacy policy">VMware's Global Privacy policy</a> and will not be disclosed or shared. You may revoke your data collection consent at any time by <Link to="/research/withdraw" cds-text="link" alt="A link to end participation in Tanzu Design user research">ending your participation in the Tanzu Design user research program.</Link></p>
                <CdsCheckbox layout="horizontal" status='neutral'>
                  <label>I consent to the collection of my data <span className="required">*</span></label>
                  <input
                    type="checkbox"
                    name="dataCollectionConsent"
                    id="DataCollectionConsent"
                    checked={dataCollectionConsent}
                    onChange={handleDataCollectionConsentChanged}
                  />
                </CdsCheckbox>
              </section>
              <section cds-layout="m-b:xl">
                <p cds-text="section" cds-layout="p-b:lg">VMware Non-disclosure agreement</p>
                <p cds-text="body" cds-layout="p-b:md">This Nondisclosure Agreement (&ldquo;<strong>Agreement</strong>&rdquo;) by and between the parties listed below is effective as of the date referenced today(&ldquo;<strong>Effective Date</strong>&rdquo;).</p>
                <p cds-text="body" cds-layout="p-b:lg">In consideration of the mutual promises contained in this Agreement, the parties hereto agree as follows.</p>
                <p cds-text="subsection" cds-layout="p-b:md">1. Confidential Information.</p>
                <p cds-text="body" cds-layout="p-b:lg">&ldquo;Confidential Information&rdquo; means non-public technical, business and other information and materials that may be disclosed or otherwise made available by one party (&ldquo;<strong>Discloser</strong>&rdquo;) (whether directly or indirectly by an affiliate) to the other Party (whether directly or indirectly to an affiliate) (&ldquo;<strong>Recipient</strong>&rdquo;), in any form, that are marked or identified as confidential or proprietary at the time of disclosure. Any information or materials that relate to VMware products or services (including pricing, product or service roadmaps, license keys, strategic marketing plans, product or service designs, technical requirements and workflows, and source code) will be considered VMware Confidential Information, whether or not marked as such.</p>
                <p cds-text="subsection" cds-layout="p-b:md">2. Responsibilities Regarding Confidential Information.</p>
                <p cds-text="body" cds-layout="p-b:sm">Recipient will:</p>
                <ol type="a" cds-text="body" cds-layout="p-b:lg" style={{ paddingLeft : '12px' }}>
                  <li>hold Discloser&rsquo;s Confidential Information in confidence and not disclose such Confidential Information to any third party;</li>
                  <li>not use Discloser&rsquo;s Confidential Information for any purpose except to provide Feedback (the &ldquo;Purpose&rdquo;); and</li>
                  <li>take reasonable precautions (at least equivalent to those Recipient takes with respect to its own similar information) to prevent unauthorized disclosure or use of Discloser&rdquo;s Confidential Information and will maintain source code in strict confidence for perpetuity.</li>
                </ol>
                <p cds-text="subsection" cds-layout="p-b:md">3. Representatives.</p>
                <p cds-text="body" cds-layout="p-b:lg">Recipient may only disclose Discloser&rsquo;s Confidential Information to its own employees, consultants, affiliates and advisors who reasonably require it to carry out their function in connection with the Purpose and have agreed in writing to terms at least as protective as those set forth in this Agreement (&ldquo;<strong>Representatives</strong>&rdquo;). Recipient is responsible for any acts or omissions of its Representatives that, if taken by Recipient, would constitute a breach of this Agreement.</p>
                <p cds-text="subsection" cds-layout="p-b:md">4. Exceptions and Clarifications.</p>
                <ol type="a" cds-text="body" cds-layout="p-b:lg" style={{ paddingLeft : '12px'}}>
                  <li cds-layout="p-b:xs">Recipient&rsquo;s obligations under this Agreement will not apply to any Confidential Information to the extent it:</li>
                  <ol type="i" cds-text="body" style={{ paddingLeft : '12px' }} cds-layout="p-b:sm">
                    <li>is now, or subsequently becomes, generally available through no wrongful act or omission of Recipient or its Representatives;</li>
                    <li>was, before receipt from Discloser, or becomes rightfully known to Recipient without confidentiality restrictions through disclosure from a source other than Discloser that does not owe a duty of confidentiality to Discloser with respect to such Confidential Information; or</li>
                    <li>is independently developed by Recipient without using any Confidential Information of Discloser.</li>
                  </ol>
                  <li>Recipient may disclose Discloser&rsquo;s Confidential Information to the extent required by law or regulation. Recipient will give Discloser reasonable advance notice of any such required disclosure and will limit the scope of such disclosure to the minimum required by the law or regulation.</li>
                  <li>Nothing in this Agreement will restrict or limit the right of Recipient to assign personnel for any purpose or to independently develop, offer or otherwise deal in products or services competitive with those of Discloser without using Discloser&rsquo;s Confidential Information.</li>
                  <li>All Confidential Information disclosed under this Agreement will remain the property of Discloser. No license or right under any intellectual property right is granted under this Agreement or by any disclosure of Confidential Information except as expressly stated in this Agreement.</li>
                </ol>
                <p cds-text="subsection" cds-layout="p-b:md">5. Warranty.</p>
                <p cds-text="body" cds-layout="p-b:lg">Discloser warrants that it has the right to disclose Confidential Information but makes no other warranties, express or implied. <strong>CONFIDENTIAL INFORMATION IS PROVIDED ON AN &ldquo;AS IS&rdquo; BASIS</strong>. Company acknowledges that VMware has not publicly announced the availability of products or services that contain features or functionality that are disclosed as part of the Confidential Information (&ldquo;Potential Features&rdquo;), that VMware has not promised or guaranteed to Company that products or services containing the Potential Features or Feedback (as defined in Section 6 below) will be announced or made available to anyone in the future, that VMware has no express or implied obligation to Company to announce or introduce any product or service that contain the Potential Features or Feedback, and that VMware may not introduce a product or service that contains the Potential Features or Feedback. Accordingly, Company acknowledges that any research that Company conducts is done entirely at Company&rsquo;s own risk. Specifically, the VMware products or services disclosed as part of the Confidential Information may contain features, functionality or modules that may not be included in the generally available version of the respective products or services, or that may be marketed separately for additional fees.</p>
                <p cds-text="subsection" cds-layout="p-b:md">6. Feedback.</p>
                <p cds-text="body" cds-layout="p-b:lg">Company may from time to time provide feedback to VMware concerning the Confidential Information (&ldquo;Feedback&rdquo;). Feedback which is provided by Company to VMware in connection with the Confidential Information or this Agreement may be used by VMware to improve or enhance its products and, accordingly, Company hereby grants VMware and it subsidiaries a non-exclusive, perpetual, irrevocable, royalty-free, worldwide right and license to use, reproduce, disclose, sublicense, modify, make, have made, distribute, sell, offer for sale, display, perform, create derivative works, permit unmodified binary distribution and otherwise exploit such Feedback without restriction.</p>
                <p cds-text="subsection" cds-layout="p-b:md">7. Term and Termination.</p>
                <p cds-text="body" cds-layout="p-b:lg">Either party may terminate the Agreement in writing, whereupon Recipient will stop all use and disclosure of Discloser&rsquo;s Confidential Information. Regardless of any expiration or termination of this Agreement, Recipient must meet its obligations with respect to Confidential Information under this Agreement for five years after receipt of that Confidential Information (except for source code, which must be kept in confidence for perpetuity). Upon written request of the Discloser, Recipient will promptly return to Discloser or destroy (or in the case of electronic data, use commercially reasonable efforts to delete or render practicably inaccessible by Recipient) Confidential Information of Discloser.</p>
                <p cds-text="body" cds-layout="p-b:lg"><strong>By checking &lsquo;I accept&rdquo;, you are confirming you personally agree to the terms and conditions set forth in the above non-disclosure agreement.</strong></p>
                <CdsCheckbox layout="horizontal" status='neutral'>
                  <label>I accept the non-disclosure agreement<span className="required">*</span></label>
                  <input
                    type="checkbox"
                    name="ndaConsent"
                    id="NdaConsent"
                    checked={ndaConsent}
                    onChange={handleNdaConsentChanged}
                  />
                </CdsCheckbox>
              </section>
              <div>
                <CdsButton action="solid" disabled id="NextAction" onClick={handleNextActionClick}>Next: {o.hasScreener ? 'Questionnaire' : 'Scheduling'}</CdsButton> <Link to="../"><CdsButton action="flat">Cancel</CdsButton></Link>
              </div>
            </div>
          </div>
        </ContentArea>
      </Layout>
    </>
  );

}

export { Consent }