import { useRef, useState } from 'react';
import Helmet from 'react-helmet';
import * as Yup from 'yup';
import { analytics, functions } from '../../lib/firebase';
import { httpsCallable } from 'firebase/functions';
import { logEvent } from 'firebase/analytics';
import { useNavigate } from 'react-router-dom';

import { CdsButton } from '@cds/react/button';
import { CdsControlMessage, CdsFormGroup } from '@cds/react/forms';
import { CdsInput } from '@cds/react/input';
import { CdsTextarea } from '@cds/react/textarea';

import { Turnstile } from '@marsidev/react-turnstile';

import Layout from '../../components/Layout';
import ContentArea from '../../components/ContentArea';

function Offboard(props) {

  const [ emailAddress, setEmailAddress ] = useState(''),
    [ feedback, setFeedback ] = useState(''),
    turnstileWidgetRef = useRef(),
    navigate = useNavigate();

  const formSchema = Yup.object({
    emailAddress : Yup.string().email().required().label('Email address')
  });

  const defaultErrorState = {
    emailAddress : {
      hasError : false,
      message : null
    }
  }

  const [ errors, setErrors ] = useState(defaultErrorState),
    [ requestStatus, setRequestStatus ] = useState('idle');

  const handleFormSubmit = async event => {
    event.preventDefault();

    const verifyToken = httpsCallable(functions, 'verifyToken'),
      sendOffboard = httpsCallable(functions, 'sendOffboard');
    
    try {
      await formSchema.validate({ emailAddress : emailAddress });
    } catch (error) {
      let message;
      switch(error.type) {
        case "required":
          message = 'Email address is required'
          break;
        case "email":
          message = 'A properly formatted email address is required'
          break;
        default:
          break;
      }
      setErrors({ ...errors, emailAddress : { hasError : true, message : message }});
      return;
    }

    try {
      const verificationResult = await verifyToken(turnstileWidgetRef.current?.getResponse());
      if (verificationResult.data.success === false) {
        // Token failed verification
        return;
      }
    } catch (error) {
      logEvent(analytics, 'exception', {
        description : error,
        fatal : true
      });
      // throw new Error(error);
    }

    try {
      setRequestStatus('pending');
      sendOffboard({ emailAddress : emailAddress, feedback : feedback });
      navigate('confirm');
    } catch (error) {
      logEvent(analytics, 'exception', {
        description : error,
        fatal : true
      });
    }

  }

  const handleEmailAddressChanged = event => { setEmailAddress(event.target.value); },
    handleFeedbackChanged = event => { setFeedback(event.target.value); }
  
  return(
    <Layout>
      <Helmet>
        <title>Tanzu Design | Withdraw from the user research program</title>
      </Helmet>
      <ContentArea>  
        <div cds-layout="grid cols@sm:12 cols@md:8">
          <div cds-layout="col@md:start-3">
            <p cds-text="display" cds-layout="p-b:xl">Withdraw from the user research program</p>
            <p cds-text="message" cds-layout="p-b:lg">We&rsquo;re sorry to see you go but look forward to partnering with you in the future. If you have any questions, please reach out to us at <a href="mailto:research@tanzudesign.io" cds-text="link" alt="A link to send an email message to the Tanzu Design research ops team">research@tanzudesign.io</a>.</p>
            <p cds-text="secondary" cds-layout="m-b:lg"><span className="required">*</span> Indicates required fields</p>
            <form onSubmit={handleFormSubmit}>
              <CdsFormGroup layout="vertical">
                <CdsInput required="true">
                  <label>Email address</label>
                  <input
                    type="text"
                    onChange={handleEmailAddressChanged}
                    name="emailAddress"
                    id="EmailAddress"
                    value={emailAddress}
                  />
                  {errors.emailAddress.hasError && <CdsControlMessage status="error">{errors.emailAddress.message}</CdsControlMessage>}
                </CdsInput>
                <CdsTextarea>
                  <label>Can you please tell us know why you no longer wish to participate?</label>
                  <textarea value={feedback} onChange={handleFeedbackChanged}></textarea>
                </CdsTextarea>
                <Turnstile ref={turnstileWidgetRef} siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY} theme="light" />
                <CdsButton type="submit" loading-state={(requestStatus === 'idle') ? 'default' : 'loading'}>Withdraw</CdsButton>
              </CdsFormGroup>
            </form>
          </div>
        </div>
      </ContentArea>
    </Layout>
  );
}

export { Offboard }