import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { analytics } from '../../lib/firebase';
import { logEvent } from 'firebase/analytics';

import Layout from "../../components/Layout";
import ContentArea from "../../components/ContentArea";

import { CdsButton } from '@cds/react/button';
import { CdsDivider } from '@cds/react/divider';
import { CdsCard } from '@cds/react/card';

import './ConfirmOnboard.css';

import opportunities from '../../data/opportunities';

function ConfirmOnboard(props) {

  useEffect(() => {
    logEvent(analytics, 'sign_up');
  });

  return(
    <Layout>
      <Helmet>
        <title>Tanzu Design | Sign up confirmed</title>
      </Helmet>
      <ContentArea>
        <div cds-layout="grid cols@sm:12 cols@md:8">
          <div cds-layout="col@md:start-3">
            <p cds-text="display" cds-layout="p-b:xl">Sign up confirmed</p>
            <p cds-text="message" cds-layout="p-b:lg">Thank you for partnering with us in the Tanzu Design user research program! Please keep an eye out for the monthly opportunities for you to participate in. If you have any questions, please reach out to us at <a href="mailto:research@tanzudesign.io" cds-text="link" alt="A link to send an email message to the Tanzu Design research ops team">research@tanzudesign.io</a>.</p>
            <p cds-text="heading" cds-layout="m-b:lg">Get Rewarded</p>
            <p cds-text="body" cds-layout="m-b:lg">We're pleased to have partnered with the <a href="https://www.vmware.com/company/rewards.html" alt="Link to VMware Rewards program" target="_blank" rel="noreferrer">VMware Rewards program</a> to <b>award research study participants 200 points per study</b> which can be redeemed for prizes from the VMware Rewards catalog. Prizes range from exclusive VMware-branded swag and charity donations to high-tech gadget and event tickets. If you haven't already joined VMware Rewards, <a href="https://www.vmware.com/company/rewards.html" alt="Link to VMware Rewards program" target="_blank" rel="noreferrer">sign up today to start earning rewards!</a></p>
            <p cds-text="title" cds-layout="p-b:lg">Research studies for you</p>
            <div cds-layout="grid cols@sm:12 cols@md:6 gap:lg">
              {opportunities.map((o, i) => (
                <CdsCard key={i}>
                  <div cds-layout="vertical gap:md">
                    <h2 cds-text="section">{o.title}</h2>
                    <CdsDivider cds-card-remove-margin></CdsDivider>
                    <div cds-text="body light">
                      {o.description}
                      <dl>
                        <dt>Anticipated length</dt>
                        <dd>{o.length} minutes</dd>
                        <dt>Personas</dt>
                        <dd>{o.personas.join(', ')}</dd>
                        <dt>Products</dt>
                        <dd>{o.products.join(', ')}</dd>
                        <dt>VMware Rewards points</dt>
                        <dd>
                          Earn {o.points} <a href="https://www.vmware.com/company/rewards.html" alt="Link to VMware Rewards program" target="_blank" rel="noreferrer">VMware Rewards points</a> for participating in this study
                        </dd>
                      </dl>
                    </div>
                    <cds-divider cds-card-remove-margin></cds-divider>
                    <div cds-layout="horizontal gap:xs align:vertical-center">
                      <a href={o.signupUrl} target="_blank" alt="Sign up for research study" rel="noreferrer"><CdsButton action="flat-inline">Sign up</CdsButton></a>
                    </div>
                  </div>
                </CdsCard>
              ))}
            </div>
          </div>
        </div>
      </ContentArea>
    </Layout>
  );
}

export { ConfirmOnboard } 