export const personas = {
  APP_DEVELOPER : "Application Developer",
  APP_OWNER : "Application Owner",
  DEV_OPS : "DevOps",
  FINANCE : "Finance",
  LOB_EXECUTIVE : "Business Line Owner/Executive",
  PLATFORM_ENGINEER : "Platform Engineer",
  PLATFORM_OWNER : "Platform Owner",
  SECURITY_TEAM : "Security Team Member"
}

export const products = {
  AAU : "Aria Automation for Secure Clouds",
  ACF : "Aria Automation Config and Aria Automation Secure Hosts",
  TAP : "Tanzu Application Platform",
  TAS : "Tanzu Application Service",
  TCS : "Tanzu Cost/CloudHealth",
  TGR : "Tanzu Guardrails",
  TIN : "Tanzu Insights",
  TMC : "Tanzu Mission Control",
  TOB : "Tanzu Observability (Aria Operations for Applications)",
  TSM : "Tanzu Service Mesh",
  TTR : "Tanzu Transformer"
}