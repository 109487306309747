import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

import { CdsButton } from '@cds/react/button';
import { CdsIcon } from '@cds/react/icon';
import { ClarityIcons, angleIcon } from '@cds/core/icon';

import ContentArea from '../../components/ContentArea';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';

import './Home.css';

import opportunities from '../../data/opportunities';

function Home(props) {

  ClarityIcons.addIcons(angleIcon);

  const Opportunities = (props) => {
    const opportunitiesCards = [];
    const limit = (opportunities.length < 3) ? opportunities.length : 3;
    const columns = (opportunities.length < 3) ? 6 : 4;

    for (let i = 0; i < limit; i++) {
      opportunitiesCards.push(
        <div className={`clr-col-lg-${columns} clr-col-12`} key={i}>
          <div className="card">
            <h3 className="card-header">{opportunities[i].title}</h3>
            <div className="card-block">
              <p className="card-text">{opportunities[i].description}</p>
              <dl>
                <dt>VMware Rewards points</dt>
                <dd>
                  Earn {opportunities[i].points} <a href="https://www.vmware.com/company/rewards.html" alt="Link to VMware Rewards program" target="_blank" rel="noreferrer">VMware Rewards points</a> for participating in this study
                </dd>
              </dl>
            </div>
            <div className="card-footer">
              <Link to={`research#Study_${opportunities[i].id}`} alt="Learn more about this research study"><CdsButton action="flat-inline">Learn more</CdsButton></Link>
            </div>
          </div>
        </div>
      )
    }
    return opportunitiesCards;

    
  }

  return(
    <>
      <Helmet>
        <title>Tanzu Design</title>
      </Helmet>
      <Header title="Tanzu Design" />
      <div className="top-rail" alt="An illustration of a standing man next to a woman seated at a computer with the woman pointing at the computer monitor" style={{ background : "#ffffff url(/img/collaboration.png) center no-repeat" }}></div>
      <div className="content-container">
        <ContentArea>
          <div cds-layout="grid cols@sm:12 cols@md:8">
            <div cds-layout="col@md:start-3">
              <p cds-text="display semibold" cds-layout="m-b:lg">Hiya! 👋</p>
              <p cds-text="section" cds-layout="m-b:xl">We&rsquo;re the Tanzu Design team and we design the product experience for VMware Tanzu which empowers developers to focus on building great apps by providing faster, more secure paths to production, and automating platform operations at scale. Using human-centered design methods and principles, we discover the needs and pain points of the people who use VMware Tanzu and thoughtfully design meaningful solutions to improve their user experience.</p>
              <p cds-text="heading" cds-layout="m-b:lg">Get rewarded</p>
              <p cds-text="body" cds-layout="m-b:lg">We&rsquo;re pleased to partner with the <a href="https://www.vmware.com/company/rewards.html" alt="Link to VMware Rewards program" target="_blank" rel="noreferrer">VMware Rewards program</a> to <b>award research study participants 200 points per study</b> which can be redeemed for prizes from the VMware Rewards catalog. Prizes range from exclusive VMware-branded swag and charity donations to high-tech gadget and event tickets. If you haven't already joined VMware Rewards, <a href="https://www.vmware.com/company/rewards.html" alt="Link to VMware Rewards program" target="_blank" rel="noreferrer">sign up today to start earning rewards!</a></p>
              <p cds-text="heading" cds-layout="m-b:lg">Join us</p>
              {(opportunities.length > 0) && <><div className="clr-row" cds-layout="m-b:lg">
                <Opportunities />
              </div>
              <div cds-layout="m-b:xl">
                <Link to="/research"><CdsButton action="solid">View all research opportunites<CdsIcon shape="angle" direction="right" /></CdsButton></Link>
              </div></>}
              <p cds-text="body" cds-layout="m-b:lg">We are looking for Application Developers, Kubernetes Platform Engineers, Platform Owners, and Application Owners that can help us shape and improve the VMware Tanzu user experience. Each month we will share 5 to 7 research topics that require hands-on user input. The research will vary from usability testing to concept validation and ideation. Sessions are designed for single participants so that we can get direct input from each person based on their unique needs. All feedback will be consolidated, evaluated, and considered as we improve all areas of the VMware Tanzu experience.</p>
              <p>
                <Link to="/join-us" alt="A link to sign up for the monthly Tanzu Design research newsletter">
                  <CdsButton>Join the research program<CdsIcon shape="angle" direction="right" /></CdsButton>
                </Link>
              </p>

            </div>
          </div>
        </ContentArea>
      </div>
      <Footer />
    </>
  );
}

export default Home;