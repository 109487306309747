import { useRef, useState } from 'react';
import Helmet from 'react-helmet';
import * as Yup from 'yup';
import _ from 'lodash';
import { analytics, functions } from '../../lib/firebase';
import { httpsCallable } from 'firebase/functions';
import { logEvent } from 'firebase/analytics';
import { Link, useNavigate } from 'react-router-dom'; 

import { CdsAlertGroup, CdsAlert } from '@cds/react/alert';
import { CdsButton } from '@cds/react/button';
import { CdsCheckbox, CdsCheckboxGroup } from '@cds/react/checkbox';
import { CdsControlMessage } from '@cds/react/forms';
import { CdsFormGroup } from '@cds/react/forms';
import { CdsInput } from '@cds/react/input';

import { Turnstile } from '@marsidev/react-turnstile';

import Layout from '../../components/Layout';
import ContentArea from '../../components/ContentArea';

import { products as tanzuProducts, personas } from '../../data/constants';

function Onboard(props) {

  const formSchema = Yup.object({
    name : Yup.string().required().label('Name'),
    emailAddress : Yup.string().email().required().label('Email address'),
    title : Yup.string().required().label('Job title'),
    communicationConsent : Yup.boolean().oneOf([true], 'You must consent to communications to join the user research program.'),
    dataCollectionConsent : Yup.boolean().oneOf([true], 'You must consent to data collection to join the user research program.')
  });

  const [ subscriber, setSubscriber ] = useState({
    name : '',
    title : '',
    emailAddress: '',
    companyName : '',
    tanzuProducts : [],
    roles : [],
    communicationConsent : false,
    dataCollectionConsent : false
  });

  const [ requestState, setRequestState ] = useState('idle'),
    [ formError, setFormError ] = useState({ hasError : false, message : null });

  const turnstileWidgetRef = useRef(),
    navigate = useNavigate();
  
  const defaultErrorState = {
    emailAddress : {
      hasError : false,
      message : null,
    },
    name : {
      hasError : false,
      message : null,
    },
    title : {
      hasError : false,
      message : null
    },
    communicationConsent : {
      hasError : false,
      message : null
    },
    dataCollectionConsent : {
      hasError : false,
      message : null
    }
  };

  const [ errors, setErrors ] = useState(defaultErrorState);

  const handleFormSubmit = async (event) => {
    
    event.preventDefault();
    setRequestState('pending');
            
    const verifyToken = httpsCallable(functions, 'verifyToken'),
      addContact = httpsCallable(functions, 'addContact');

    setErrors(defaultErrorState);
    setFormError({ hasError : false, message : null });
    try {  
      await formSchema.validate(subscriber, { abortEarly : false });
    } catch (error) {
      setRequestState('idle');
      let fieldErrors;
      error.inner.forEach(field => {
        fieldErrors = { ...fieldErrors, [field.path] : { hasError : true, message : field.message }}
      });
      setErrors({ ...errors, ...fieldErrors });
      setFormError({ hasError : true, message : "Please ensure all required fields have been completed and the required consent has been given."})
      return;
    }
    
    try {
      const verificationResult = await verifyToken(turnstileWidgetRef.current?.getResponse());
      if (verificationResult.data.success === false) {
        if (process.env.NODE_ENV === 'dev') {
          console.error(verificationResult);
        }
        logEvent(analytics, 'exception', {
          description : `Cloudflare Turnstile token verification failed: ${verificationResult.data}`,
          fatal : true
        });
        return;
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'dev') {
        console.log('VALIDATION ERROR');
        console.error(error);
      }
      logEvent(analytics, 'exception', {
        description : error.message,
        fatal : true
      });
    }

    addContact(subscriber)
      .then(() => {
        navigate('confirm');
      })
      .catch(e => {
        if (e.code === 'functions/already-exists') {
          setErrors({ ...errors, emailAddress : { hasError : true, message : 'This email address is already subscribed'}});
          setRequestState('idle');
        } else {
          setFormError({ hasError : true, message : "Sorry, we were not able to sign you up due to an error on our side."});
        }
        logEvent(analytics, 'exception', {
          description : e,
          fatal : true
        });
      })

  };

  const handleEmailAddressChanged = event => { setSubscriber({ ...subscriber, emailAddress : event.target.value }); },
    handleNameChanged = event => { setSubscriber({ ...subscriber, name : event.target.value }); },
    handleTitleChanged = event => { setSubscriber({ ...subscriber, title : event.target.value }); },
    handleCompanyNameChanged = event => { setSubscriber({ ...subscriber, companyName : event.target.value }); },
    handleCommunicationConsentChanged = event => { setSubscriber({ ...subscriber, communicationConsent : event.target.checked }); },
    handleDataCollectionConsentChanged = event => { setSubscriber({ ...subscriber, dataCollectionConsent : event.target.checked }); };
  
  const handleTanzuProductChanged = e => {
    if (e.target.checked === true) {
      setSubscriber({ ...subscriber, tanzuProducts : [ ...subscriber.tanzuProducts, e.target.value ]});
    } else {
      setSubscriber({ ...subscriber, tanzuProducts : _.without(subscriber.tanzuProducts, e.target.value) });
    }
  }

  const handleRolesChange = e => {
    if (e.target.checked === true) {
      setSubscriber({ ...subscriber, roles : [ ...subscriber.roles, e.target.value ]});
    } else {
      setSubscriber({ ...subscriber, roles : _.without(subscriber.roles, e.target.value) });
    }
  }

  return(
    <Layout>
      <Helmet>
        <title>Tanzu Design | Join the User Research Program</title>
      </Helmet>
      <ContentArea>
        <div cds-layout="grid cols@sm:12 cols@md:8">
          <div cds-layout="col@md:start-3">
            <p cds-text="display" cds-layout="p-b:xl">Join the User Research Program</p>
            <p cds-text="body" cds-layout="p-b:xl">Thank you for your interest in participating in the Tanzu Design user research program. Each month, we will highlight available research opportunities for you to participate in. If you have any questions about the program, please reach out to us at <a href="mailto:research@tanzudesign.io" cds-text="link" alt="A link to send an email message to the Tanzu Design research ops team">research@tanzudesign.io</a>.</p>
            <p cds-text="secondary" cds-layout="m-b:lg"><span className="required">*</span> Indicates required fields</p>
            {formError.hasError &&
              <CdsAlertGroup cds-layout="m-b:lg" status="danger" aria-label={formError.message}>
                <CdsAlert>{formError.message}</CdsAlert>
              </CdsAlertGroup>
            }
            <form onSubmit={handleFormSubmit}>
              <CdsFormGroup layout="vertical">
                <CdsInput required="true">
                  <label>Name</label>
                  <input
                    type="text"
                    onChange={handleNameChanged}
                    name="name"
                    id="Name"
                    value={subscriber.name}
                  />
                  {errors.name.hasError && <CdsControlMessage status="error">{errors.name.message}</CdsControlMessage>}
                </CdsInput>
                <CdsInput required="true">
                  <label>Email address</label>
                  <input
                    type="text"
                    onChange={handleEmailAddressChanged}
                    name="emailAddress"
                    id="EmailAddress"
                    value={subscriber.emailAddress}
                  />
                  {errors.emailAddress.hasError && <CdsControlMessage status="error">{errors.emailAddress.message}</CdsControlMessage>}
                </CdsInput>
                <CdsInput required="true">
                  <label>Job title </label>
                  <input
                    type="text"
                    onChange={handleTitleChanged}
                    name="title"
                    id="Title"
                    value={subscriber.title}
                  />
                  {errors.title.hasError && <CdsControlMessage status="error">{errors.title.message}</CdsControlMessage>}
                </CdsInput>
                <CdsInput>
                  <label>Company name</label>
                  <input
                    type="text"
                    onChange={handleCompanyNameChanged}
                    name="companyName"
                    id="CompanyName"
                    value={subscriber.companyName}
                  />
                </CdsInput>
                <CdsCheckboxGroup layout="vertical" cds-layout="m-b:lg">
                  <label>Which VMware Tanzu products do you currently use?</label>
                  {/* {tanzuProductsList} */}
                  {Object.keys(tanzuProducts).map(key => (
                    <CdsCheckbox key={key}>
                      <label>{tanzuProducts[key]}</label>
                      <input type="checkbox" value={key} onChange={handleTanzuProductChanged} checked={(subscriber.tanzuProducts.indexOf(key) === -1) ? false : true} />
                    </CdsCheckbox>
                  ))}
                </CdsCheckboxGroup>
                <CdsCheckboxGroup layout="vertical" cds-layout="m-b:lg">
                  <label>Which roles do you have experience with?</label>
                  {Object.keys(personas).map(key => (
                    <CdsCheckbox key={key}>
                      <label>{personas[key]}</label>
                      <input type="checkbox" value={key} onChange={handleRolesChange} checked={(subscriber.roles.indexOf(key) === -1) ? false : true } />
                    </CdsCheckbox>
                  ))}
                </CdsCheckboxGroup>
                <div cds-layout="vertical gap:lg m-b:lg">
                  <p cds-text="h6">Communication consent</p>
                  <p cds-text="body">By participating in the Tanzu Design user research program, you consent to receiving regular email messages from the Tanzu Design team about available user experience research opportunities. You may revoke your communication consent at any time by <Link to="/withdraw" cds-text="link" alt="A link to end participation in Tanzu Design user research">ending your participation in the Tanzu Design user research program</Link>.</p>
                  <CdsCheckbox layout="horizontal" status={(errors.communicationConsent.hasError) ? 'error' : 'neutral'}>
                    <label>I consent to receiving communications <span className="required">*</span></label>
                    <input
                      type="checkbox"
                      name="communicationConsent"
                      id="CommunicationConsent"
                      checked={subscriber.communicationConsent}
                      onChange={handleCommunicationConsentChanged}
                    />
                    {errors.communicationConsent.hasError && <CdsControlMessage status="error">{errors.communicationConsent.message}</CdsControlMessage>}
                  </CdsCheckbox>
                </div>
                <div cds-layout="vertical gap:lg m-b:lg">
                  <p cds-text="h6">Data collection consent</p>
                  <p cds-text="body">By participating in the Tanzu Design user research program, you consent to the Tanzu Design team collecting and retaining your personally identifiable information (PII) and audio and/or video recordings to facilitate user research for product improvement. All personally identifiable information (PII) is managed in accordance with <a href="https://www.vmware.com/help/privacy.html" target="_blank" cds-text="link" rel="noreferrer" alt="VMware's Global Privacy policy">VMware&rsquo;s Global Privacy policy</a> and will not be disclosed or shared. You may revoke your data collection consent at any time by <Link to="/withdraw" cds-text="link" alt="A link to end participation in the Tanzu Design user research program">ending your participation in the Tanzu Design user research program.</Link></p>
                  <CdsCheckbox layout="horizontal" status={(errors.dataCollectionConsent.hasError) ? 'error' : 'neutral'}>
                    <label>I consent to the collection of my data <span className="required">*</span></label>
                    <input
                      type="checkbox"
                      name="dataCollectionConsent"
                      id="DataCollectionConsent"
                      checked={subscriber.dataCollectionConsent}
                      onChange={handleDataCollectionConsentChanged}
                    />
                    {errors.dataCollectionConsent.hasError && <CdsControlMessage status="error">{errors.dataCollectionConsent.message}</CdsControlMessage>}
                  </CdsCheckbox>
                </div>
                <Turnstile ref={turnstileWidgetRef} siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY} theme="light" />
                <CdsButton loading-state={(requestState === 'idle') ? 'default' : 'loading'}>Sign up</CdsButton>
              </CdsFormGroup>
            </form>
          </div>
        </div>
      </ContentArea>
    </Layout>
  );
}

export { Onboard }