import './Footer.css';

function Footer(props) {
  
  const date = new Date();

  return(
    <>
      <footer cds-layout="grid cols@sm:12 cols@md:8">
        <div cds-layout="col@md:start-3">
          <p cds-text="secondary center" cds-layout="p-y:md">&copy; {date.getFullYear()} Broadcom | <a href="https://www.vmware.com/help/legal.html" target="_blank" cds-text="link" alt="A link to VMware's Terms and Conditions" rel="noreferrer">Terms &amp; Conditions</a> | <a href="https://www.vmware.com/help/privacy.html" target="_blank" cds-text="link" alt="A link to VMware's Privacy Notice" rel="noreferrer">Privacy Notice</a> | <a href="https://www.vmware.com/help/privacy/california-privacy-rights.html" target="_blank" cds-text="link" alt="A link to VMware's California Privacy Rights" rel="noreferrer">Your California Privacy Rights</a></p>
        </div>
      </footer>
    </>
  );
}

export default Footer;